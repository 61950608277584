const fetchSessions = async (
  axiosPrivate,
  startDate = null,
  endDate = null
) => {
  try {
    const params =
      startDate && endDate ? { start_date: startDate, end_date: endDate } : {};
    const response = await axiosPrivate.get("/teams/sessions/", {
      method: "GET",
      params: params,
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching team sessions:", error);
    throw error;
  }
};

const fetchSessionDetails = async (sessionId, axiosPrivate) => {
  try {
    const response = await axiosPrivate.get(
      `/teams/sessiondetails/${sessionId}/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Fetching session details failed:", error);
    throw error;
  }
};

const fetchTeamSessions = async (startDate, endDate, axiosPrivate) => {
  try {
    const params = new URLSearchParams({
      start_date: startDate,
      end_date: endDate,
    });
    const response = await axiosPrivate.get(
      `/teams/sessions/?${params.toString()}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching team sessions:", error);
    throw error;
  }
};

const fetchPlSessions = async (
  selPlayerCal,
  startDate,
  endDate,
  rosterInfo,
  axiosPrivate
) => {
  try {
    let newselPlayerCal = Array.isArray(selPlayerCal)
      ? selPlayerCal
      : [selPlayerCal];

    const proms = newselPlayerCal.map((spid) => {
      return axiosPrivate.get(
        `/teams/sessionsperplayer/${spid}/${startDate}/${endDate}/`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        }
      );
    });

    const resps = await Promise.all(proms);
    const data = resps.map((item) => item.data);

    const mergedData = data.map((playerData) => {
      const playerInfo = rosterInfo.find(
        (info) => info.PLAYER_ID === playerData.PLAYER_ID
      );
      return playerInfo ? { ...playerData, ...playerInfo } : playerData;
    });

    return mergedData;
  } catch (error) {
    console.error("Error fetching player sessions:", error);
    throw error;
  }
};

export {
  fetchSessions,
  fetchSessionDetails,
  fetchTeamSessions,
  fetchPlSessions,
};
