import { useState, useEffect } from "react";
import { fetchWLGroups, fetchZones, fetchZone6, fetchTrend } from "src/services/apis";

import { useCapacity } from "./useCapacity";
import { usePlayerACWR } from "./usePlayerACWR";
import { prepMonitoringGraph, prepMonitoringRadar, prepWLDataSet, prepZone6Data, prepZonesData } from "src/utils/chartsUtils";
import { useGlobalState } from "src/contexts";
import { useAxiosPrivate } from "./useAxiosPrivate";


const useChartDataWL = (
    players,
    selPlayer,
    startDate,
    endDate,
    selGroup,
    loads
) => {

    const { playersLoad } = usePlayerACWR(selPlayer, startDate, endDate, selGroup);
    const { trend } = useTrend(selPlayer[0], selGroup, startDate, endDate);
    const { state } = useGlobalState();

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        setChartData(prepWLDataSet(loads, selPlayer, playersLoad, trend, players, state));

    }, [playersLoad, trend]);

    return { chartData };
};


const useZoneData = (selPlayer, players, startDate, endDate, wlgroup) => {

    const { zones, zone6 } = useZones(selPlayer, startDate, endDate, wlgroup);
    const [zone6Data, setZone6Data] = useState(null);
    const [zonesData, setZonesData] = useState(null);

    useEffect(() => {
        setZone6Data(prepZone6Data(zone6, selPlayer, players))
        setZonesData(prepZonesData(zones, selPlayer, players));

    }, [zone6, zones]);

    return { zone6Data, zonesData };
};


const useMonitoringCharts = (
    selectedPlayersIds,
    muscles,
    startDate,
    endDate,
    loads
) => {
    const { capacity } = useCapacity(selectedPlayersIds);
    const { playersLoad } = usePlayerACWR(
        selectedPlayersIds,
        startDate,
        endDate
    );
    const [chartData, setChartData] = useState([]);
    const [chartDataRadar, setChartDataRadar] = useState([]);

    useEffect(() => {
        if (selectedPlayersIds && playersLoad.length) {
            setChartData(prepMonitoringGraph(loads, selectedPlayersIds, playersLoad));
        }
    }, [playersLoad]);

    useEffect(() => {
        if (selectedPlayersIds) {
            setChartDataRadar(prepMonitoringRadar(selectedPlayersIds, capacity, muscles));
        }
    }, [capacity]);

    return { chartData, chartDataRadar };
};


const useWLGroups = () => {
    const [groups, setGroups] = useState([]);
    const { axiosPrivate } = useAxiosPrivate();

    useEffect(() => {
        const loadGroups = async () => {
            try {
                const data = await fetchWLGroups(axiosPrivate);
                setGroups(data);
            } catch (error) {
                console.error("Error loading groups:", error);
            }
        };

        loadGroups();
    }, []);

    return { groups };
};


const useZones = (playersIds, startDate, endDate, wlgroup = 1) => {
    const [zones, setZones] = useState([]);
    const [zone6, setZone6] = useState([]);
    const { axiosPrivate } = useAxiosPrivate();

    useEffect(() => {
        const loadZones = async () => {
            try {
                const promsZones = playersIds.map((pid) =>
                    fetchZones(pid, wlgroup, startDate, endDate, axiosPrivate)
                );

                const promsZone6 = playersIds.map((pid) =>
                    fetchZone6(pid, wlgroup, startDate, endDate, axiosPrivate)
                );

                const dataZones = await Promise.all(promsZones);
                setZones(dataZones);

                const dataZone6 = await Promise.all(promsZone6);
                setZone6(dataZone6);

            } catch (error) {
                console.error("Error loading zones, zone6:", error);
            }
        };

        if (playersIds && playersIds.length && wlgroup) {
            loadZones();
        }
    }, [playersIds, wlgroup, startDate, endDate]);

    return { zones, zone6 };
};


const useTrend = (pid, wlgid, startDate, endDate) => {
    const [trend, setTrend] = useState([]);
    const { axiosPrivate } = useAxiosPrivate();

    useEffect(() => {
        const loadTrend = async () => {
            try {
                const data = await fetchTrend(pid, wlgid, startDate, endDate, axiosPrivate);
                setTrend(data);
            } catch (error) {
                console.error("Error loading trend data:", error);
            }
        };

        if (pid && wlgid) {
            loadTrend();
        }

    }, [pid, wlgid, startDate, endDate]);

    return { trend };
};


export {
    useChartDataWL,
    useMonitoringCharts,
    useTrend,
    useWLGroups,
    useZones,
    useZoneData,
};
