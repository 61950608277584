import { useState, useEffect } from "react";
import { fetchKinexonSessions } from "src/services/apis";
import { useAxiosPrivate } from "./useAxiosPrivate";

const useKinexonSession = () => {
  const [kinSessions, setKinSessions] = useState(null);
  const { axiosPrivate } = useAxiosPrivate();

  useEffect(() => {
    const loadKinexonSessions = async () => {
      try {
        const response = await fetchKinexonSessions(axiosPrivate);
        setKinSessions(response);
      } catch (error) {
        console.error("Error loading Kinexon sessions:", error);
      }
    };

    loadKinexonSessions();
  }, []);

  return { kinSessions };
};

export { useKinexonSession };
