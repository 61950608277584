import { useState, useEffect } from "react";
import { fetchMetrics } from "src/services/apis/metricsApi";
import { useRoster } from "./useRoster";
import { useAxiosPrivate } from "./useAxiosPrivate";

const useMetrics = (startDate, endDate) => {
    const [metrics, setMetrics] = useState([]);
    const { players } = useRoster();
    const { axiosPrivate } = useAxiosPrivate();

    useEffect(() => {
        const loadPlayers = async () => {
            try {
                const data = await fetchMetrics(startDate, endDate, axiosPrivate);

                const mergedData = data.map((team) => {
                    const player = players.find((p) => p.PLAYER_ID === team.PLAYER_ID);
                    if (player) {
                        return {
                            ...player,
                            ...team,
                        };
                    }

                    return team; // or handle the case where player is not found
                });


                setMetrics(mergedData);

            } catch (error) {
                console.error("Error loading metrics:", error);
            }
        };

        if (players.length) {
            loadPlayers();
        }
    }, [startDate, endDate, players]);

    return { metrics };
};

export { useMetrics };
