const fetchMetrics = async (startDate, endDate, axiosPrivate) => {
  try {
    const response = await axiosPrivate.get(`/teams/metrics/${startDate}/${endDate}`, {
      method: "GET",
      headers: {
        Accept: 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching metrics:", error);
    throw error;
  }
};

export { fetchMetrics };
