const sortRoster = (array) => {
    return array.sort((a, b) => {
        const nameA = `${a.LAST_NAME} ${a.FIRST_NAME}`.toUpperCase();
        const nameB = `${b.LAST_NAME} ${b.FIRST_NAME}`.toUpperCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
}

export default sortRoster;
