import { useState, useEffect } from "react";
import { useGlobalState } from "src/contexts/GlobalStateContext";
import { fetchRoster } from "src/services/apis";
import sortRoster from "src/utils/sortRoster";
import { useAuth } from "./useAuth";
import {useAxiosPrivate} from "./useAxiosPrivate";

const useRoster = () => {
  const [players, setPlayers] = useState([]);
  const { state, dispatch } = useGlobalState();

  const { auth } = useAuth();
  const {axiosPrivate} = useAxiosPrivate();


  useEffect(() => {
    const loadPlayers = async () => {
      try {
        if (!state.rosterInfo) {
          const data = await fetchRoster(axiosPrivate);

          setPlayers(sortRoster(data));
          dispatch({
            type: "updateState",
            payload: { rosterInfo: data },
          });

        } else {
          setPlayers(sortRoster(state.rosterInfo));
        }

      } catch (error) {
        console.error("Error loading player roster:", error);
      }
    };

    if (auth.user) {
      loadPlayers();
    }

  }, []);

  return { players };
};

export { useRoster };
