// Not yet Implimented!
const fetchPlInjuries = async (playerId, startDate, endDate, axiosPrivate) => {
    try {
        const response = await axiosPrivate.get(`/teams/injuries/${playerId}/${startDate}/${endDate}/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching player injuries:", error);
        throw error;
    }
};

export { fetchPlInjuries };
