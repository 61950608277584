import React, { createContext, useContext, useReducer, useEffect } from "react";

const ACTIONS = {
  UPDATE_STATE: "updateState",
  CLEAR_STATE: "clearState",
};

const INITIAL_STATE = {
  teamInfo: null,
  userInfo: null,
  rosterInfo: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_STATE:
      return { ...state, ...action.payload };
    case ACTIONS.CLEAR_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

const GlobalStateContext = createContext();

export const useGlobalState = () => {
  return useContext(GlobalStateContext);
};

const getInitialState = () => {
  const storedState = JSON.parse(localStorage.getItem("globalState"));
  return storedState ? storedState : INITIAL_STATE;
};

export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE, getInitialState);

  useEffect(() => {
    localStorage.setItem("globalState", JSON.stringify(state));
  }, [state]);

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
