import { useState, useEffect } from "react";

const CONVERTOR = {
    "kinexon": "KinexonTourSteps",
    "axiamo": "AxiamoTourSteps",
    "statsports": "StatsportsTourSteps",
    "wimu": "WimuTourSteps",
  };


const useSteps = (path, inputType) => {
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        const loadSteps = async () => {
            let steps;
            const importedSteps = await import(`src/components/tour-page/steps/${path}`);

            if (!inputType) {

                steps = importedSteps.default;

            } else {

                const gameSteps = await import(`src/components/tour-page/steps/modals-tour-steps/GameTourSteps`);
                const sensorSteps = await import(`src/components/tour-page/steps/modals-tour-steps/${CONVERTOR[inputType]}`);

                steps = [
                    ...importedSteps.default(inputType),
                    ...gameSteps.default,
                    ...sensorSteps.default
                ];
            }

            setSteps(steps);
        };

        loadSteps();
    }, [path]);

    return { steps };
};

export { useSteps };
