const fetchAvatar = async (axiosPrivate) => {
    try {
        const response = await axiosPrivate.get("/auth/user/avatar/", {
            headers: {
                Accept: "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching avatar:", error);
        throw error;
    }
};

export { fetchAvatar };
