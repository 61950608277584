let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

export const INITIAL_SESSIONS = [
  {
    id: createEventId(),
    //title: "Practice",
    start: "2023-03-28",
    display: "background",
    color: "#0000FF",
  },
  {
    id: createEventId(),
    //title: "Imputed",
    start: "2023-04-01",
    display: "background",
    color: "#FFA500",
  },
  {
    id: createEventId(),
    //title: "Game",
    start: todayStr,
    display: "background",
    color: "#00FF00",
  },
  {
    id: createEventId(),
    //title: "Game",
    start: "2023-04-19",
    display: "background",
    color: "#00FF00",
  },
  {
    id: createEventId(),
    //title: "Practice",
    start: "2023-05-05",
    display: "background",
    color: "#0000FF",
  },
];

export function createEventId() {
  return String(eventGuid++);
}
