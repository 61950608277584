import { createEventId } from "src/views/plugins/calendar/event-utils copy";

const playerSessionsToEvents = (data, colors, explanations) => {
  const events = [];

  data.forEach((playerData) => {
    playerData.SESSION.forEach((item) => {
      let color = "#0000FF";
      for (let i = 0; i < explanations.length; i++) {
        if (item.Type === explanations[i]) {
          color = colors[i];
        }
      }
      events.push({
        id: createEventId(),
        title: item.Type,
        start: item.REF_DATE.slice(0, 10),
        end: item.REF_DATE.slice(0, 10),
        color: color,
        extendedProps: {
          description: playerData.LAST_NAME,
        },
      });
    });
  });
  return events;
};

const sessionsToEvents = (data, colors, explanations) => {
  const events = [];

  data.forEach((session) => {
    if (session.Status === "Processed") {
      let color = "#0000FF";
      for (let i = 0; i < explanations.length; i++) {
        if (session.Type === explanations[i]) {
          color = colors[i];
        }
      }

      let title = [
        "Practice",
        "Individual",
        "Game-1",
        "Game-2",
        "Game-3",
        "Game-4",
        "Game+1",
        "Game+2",
      ].includes(session.Type)
        ? "Practice"
        : session.Type;

      events.push({
        id: createEventId(),
        title: title,
        start: session.Date.slice(0, 10),
        end: session.Date.slice(0, 10),
        color: color,
        extendedProps: {
          description: `${session.Type}`,
        },
      });
    }
  });

  return events;
};

export { sessionsToEvents, playerSessionsToEvents };
