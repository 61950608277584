import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { axiosPrivate } from "src/services/apis/axios";
import { useAuth } from "./useAuth";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        try {
            const requestIntercept = axiosPrivate.interceptors.request.use(
                (config) => {
                    if (!config.headers["Authorization"] && auth?.accessToken) {
                        config.headers["Authorization"] = `Bearer ${auth.accessToken}`;
                    }
                    return config;
                },
                (error) => {
                    Promise.reject(error)
                } // Handle request errors
            );

            const responseIntercept = axiosPrivate.interceptors.response.use(
                (response) => response,
                async (error) => {
                    const prevRequest = error?.config;

                    if ([401, 403].includes(error?.response?.status) && !prevRequest?.sent) {
                        prevRequest.sent = true;
                        try {
                            const newAccessToken = await refresh();
                            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

                            // console.log("Retrying request with new access token...");
                            return axiosPrivate(prevRequest);
                        } catch (refreshError) {
                            console.error("Failed to refresh token:", refreshError);
                            return Promise.reject(refreshError);
                        }
                    }
                    return Promise.reject(error);
                }
            );

            return () => {
                axiosPrivate.interceptors.request.eject(requestIntercept);
                axiosPrivate.interceptors.response.eject(responseIntercept);
            };
        } catch (error) {
            console.error(error)
        }

    }, [auth]);

    return { axiosPrivate };
};

export { useAxiosPrivate };
