import { useState } from "react";
const { fetchPlInjuries } = require("src/services/apis");

// No injuriesApi call yet implimented!!
const useInjuries = (playerId, startDate, endDate) => {
    const [injuries, setInjuries] = useState([]);
    // const axiosPrivate = useAxiosPrivate();

    // useEffect(() => {
    //     if (!playerId) return;

    //     const loadPlayerInjuries = async () => {
    //         try {
    //             const data = await fetchPlayerInjuries(playerId, startDate, endDate, axiosPrivate);
    //             setInjuries(data);
    //         } catch (error) {
    //             console.error("Error loading player injuries:", error);
    //         }
    //     };

    //     loadPlayerInjuries();
    // }, [playerId, startDate, endDate]);

    return { injuries };
};

export { useInjuries }