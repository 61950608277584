const logout = async (axiosPrivate) => {
  try {
        const response = await axiosPrivate.post("/auth/logout/");
        return response.data;
    } catch (error) {
        console.error("Error during logout:", error);
        throw error;
    }
};

export { logout };
