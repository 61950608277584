import axios from 'src/services/apis/axios';
import { useAuth } from './useAuth';

const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();

    const refresh = async () => {
        try {
            const response = await axios.get('/auth/token/refresh/', {
                withCredentials: true
            });

            setAuth({ ...auth, accessToken: response.data.access });

            return response.data.access;

        } catch (error) {
            setAuth({});
            console.error("Error refreshing token:", error);
        }

    }
    return refresh;
};

export default useRefreshToken;
