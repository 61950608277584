const fetchTeamIcon = async (axiosPrivate) => {
    try {
        const response = await axiosPrivate.get("/teams/avatar/",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            }
        );
        return response.data;

    } catch (error) {
        if (error.response) {
            console.error('Error Response Data:', error.response.data);
            console.error('Error Response Status:', error.response.status);
            console.error('Error Response Headers:', error.response.headers);
        } else if (error.request) {
            console.error('Error Request:', error.request);
        } else {
            console.error('Error Message:', error.message);
        }

        console.error('Error fetching Team Icon:', error);
        throw error;
    }
}

export { fetchTeamIcon };
