const fetchWLGroups = async (axiosPrivate) => {
  try {
    const response = await axiosPrivate.get("/teams/wlgroups/", {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching groups:", error);
    throw error;
  }
};

const fetchZones = async (pid, wlgroup, startDate, endDate, axiosPrivate) => {
  try {
    const response = await axiosPrivate.get(
      `/teams/zonesperplayer/${pid}/${wlgroup}/${startDate}/${endDate}/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;

  } catch (error) {
    console.error("Error fetching zones:", error);
    throw error;
  }
};

const fetchZone6 = async (pid, wlgroup, startDate, endDate, axiosPrivate) => {
  try {
    const response = await axiosPrivate.get(
      `/teams/zone6/${pid}/${wlgroup}/${startDate}/${endDate}/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data.map((record) => ({
      ...record,
      date: new Date(record.date),
    }));
  } catch (error) {
    console.error("Error fetching zone6:", error);
    throw error;
  }
};

const fetchTrend = async (pid, wlgid, startDate, endDate, axiosPrivate) => {
  try {
    const response = await axiosPrivate.get(
      `teams/trendline/${pid}/${wlgid}/${startDate}/${endDate}/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching trend data:", error);
    throw error;
  }
};


export { fetchWLGroups, fetchTrend, fetchZone6, fetchZones }