import axios from "src/services/apis/axios";

// Login API Call
const login = async (username, password, setAuth) => {
    try {
        const response = await axios.post(
            "/auth/login/",
            JSON.stringify({ username, password }),
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                withCredentials: true
            }
        );
        const accessToken = response?.data?.access;
        setAuth({ user: "Authenticated", accessToken });
        return response.data;
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    }
};

export { login };
