import { useState, useEffect } from "react";
import {
  fetchPlSessions,
  fetchSessions,
  fetchSessionDetails,
  fetchTeamSessions,
} from "src/services/apis";
import { useRoster } from "./useRoster";
import sortRoster from "src/utils/sortRoster";
import { useAxiosPrivate } from "./useAxiosPrivate";

const useSessions = () => {
  const [sessions, setSessions] = useState([]);
  const { axiosPrivate } = useAxiosPrivate();

  const loadSessions = async () => {
    try {
      const data = await fetchSessions(axiosPrivate);
      data.forEach((item) => {
        item.Date = item.Date.split("T")[0];
      });
      setSessions(data);
    } catch (error) {
      console.error("Load sessions failed:", error);
    }
  };

  useEffect(() => {
    loadSessions();
    const intervalId = setInterval(loadSessions, 5000); // Refresh every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  return { sessions, triggerLoadSessions: loadSessions };
};

const useSessionDetails = (sessionId) => {
  const [sessionDetails, setSessionDetails] = useState(null);
  const { players } = useRoster();
  const { axiosPrivate } = useAxiosPrivate();

  useEffect(() => {
    const loadSessionDetails = async () => {
      try {
        const data = await fetchSessionDetails(sessionId, axiosPrivate);

        const mergedData = data.players.reduce((acc, team) => {
          const player = players.find((p) => p.PLAYER_ID === team);
          if (player) {
            acc.push({
              PLAYER_ID: team,
              ...player,
            });
          }
          return acc;
        }, []);

        const sortedPlayers = sortRoster(mergedData);

        const playerData = sortedPlayers.map((player) => {
          return `${player.FIRST_NAME} ${player.LAST_NAME}`;
        });

        const sortedData = {
          ...data,
          players: playerData,
        };
        setSessionDetails(sortedData);
      } catch (error) {
        console.error("Load session details failed:", error);
      }
    };

    if (sessionId) {
      loadSessionDetails();
      const interval = setInterval(loadSessionDetails, 5000); // Refresh every 5 seconds

      return () => clearInterval(interval);
    }
  }, [sessionId]);

  return { sessionDetails };
};

const usePlayerSessions = (playerId, startDate, endDate) => {
  const [sessions, setSessions] = useState([]);
  const { players } = useRoster();
  const { axiosPrivate } = useAxiosPrivate();

  useEffect(() => {
    const loadSessions = async () => {
      try {
        const data = await fetchPlSessions(
          playerId,
          startDate,
          endDate,
          players,
          axiosPrivate
        );
        setSessions(data);
      } catch (error) {
        console.error("Error loading player sessions:", error);
      }
    };

    if (playerId) {
      loadSessions();
    }
  }, [playerId, startDate, endDate]);

  return { sessions };
};

const useTeamSessions = (startDate, endDate) => {
  const [teamSessions, setTeamSessions] = useState([]);
  const { axiosPrivate } = useAxiosPrivate();

  useEffect(() => {
    const loadTeamSessions = async () => {
      try {
        const data = await fetchTeamSessions(startDate, endDate, axiosPrivate);
        setTeamSessions(data);
      } catch (error) {
        console.error("Error loading team sessions:", error);
      }
    };

    loadTeamSessions();
  }, [startDate, endDate]);

  return { teamSessions };
};

export { useSessions, useSessionDetails, usePlayerSessions, useTeamSessions };
