import { useState, useEffect } from "react";
import { fetchPlayerACWR } from "src/services/apis";
import {useAxiosPrivate} from "./useAxiosPrivate";

const usePlayerACWR = (playerIds, startDate, endDate, group = 1) => {
    const [playersLoad, setPlayersLoad] = useState([]);
    const {axiosPrivate} = useAxiosPrivate();
    
    useEffect(() => {
        const loadPlayersLoad = async () => {
            try {
                const proms = playerIds.map((pid) => {
                    return fetchPlayerACWR(pid, group, startDate, endDate, axiosPrivate)
                });

                const data = await Promise.all(proms);
                setPlayersLoad(data);

            } catch (error) {
                console.error("Error loading player data:", error);
            }
        };

        if (playerIds && playerIds.length && group) {
            loadPlayersLoad();
        }

    }, [JSON.stringify(playerIds), group, startDate, endDate]);

    return { playersLoad };
};

export { usePlayerACWR };
