import { createContext, useEffect, useState } from "react";
import axios from "src/services/apis/axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [loading, setLoading] = useState(true);

    const verifyAccessToken = async () => {
        try {
            const refreshResponse = await axios.get('/auth/token/refresh/', {
                withCredentials: true
            });

            const requestBody = { token: refreshResponse.data.access }

            const response = await axios.post("/auth/token/verify/", requestBody);
            if (response?.data?.access)
                setAuth({ user: "Authenticated", accessToken: refreshResponse.data.access })

        } catch (err) {
            localStorage.clear();
            console.error("Access and Refresh token is invalid:", err);
            throw new Error("Invalid access token");
        }
    };

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                await verifyAccessToken();
            } catch (error) {
                console.error("Error initializing authentication:", error);
                setAuth({}); // Reset auth state on error
            } finally {
                setLoading(false)
            }
        };

        initializeAuth();
    }, []);

    if (loading)
        return (<></>)


    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}
