const fetchAveragePlCapacity = async (playerId, startDate, endDate, axiosPrivate) => {
  try {
        const response = await axiosPrivate.get(`/teams/averagecapacity/${playerId}/${startDate}/${endDate}/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching player capacity:", error);
        throw error;
    }
};

const fetchCapacity = async (pid, date, axiosPrivate) => {
  try {
        let endpoint;
        if (date) {
            endpoint = `capacity/${pid}/${date}/`;
        } else {
            endpoint = `capacitydash/${pid}/`;
        }

        const response = await axiosPrivate.get(`/teams/${endpoint}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            }
        )

        return response.data;

    } catch (error) {
        console.error('Error fetching Capacity:', error);
        throw error;
    }
};

export { fetchAveragePlCapacity, fetchCapacity };
