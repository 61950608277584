import { useState, useEffect } from "react";
import { fetchRosterGood } from "src/services/apis";
import { useRoster } from "./useRoster";
import sortRoster from "src/utils/sortRoster";
import {useAxiosPrivate} from "./useAxiosPrivate";

const useRosterGood = () => {
  const [rosterGood, setRosterGood] = useState([]);
  const { players } = useRoster();
  const {axiosPrivate} = useAxiosPrivate();

  useEffect(() => {
    const loadPlayers = async () => {
      try {
        const data = await fetchRosterGood(axiosPrivate);

        const mergedData = data.map((team) => {
          const player = players.find((p) => p.PLAYER_ID === team.PLAYER_ID);
          if (player) {
            return {
              ...player,
              ...team,
            };
          }

          return team; // or handle the case where player is not found
        });

        setRosterGood(sortRoster(mergedData));
        
      } catch (error) {
        console.error("Error loading player roster good:", error);
      }
    };

    if (players.length) {
      loadPlayers();
    }

  }, [players]);

  return { rosterGood };
};

export { useRosterGood };
