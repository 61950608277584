const fetchMuscles = async (axiosPrivate) => {
  try {
    const response = await axiosPrivate.get("/teams/muscles/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    )
    return response.data;

  } catch (error) {
    console.error('Error fetching player ACWR:', error);
    throw error;
  }
};

const fetchMyoPerMuscle = async (selPlayer, startDate, endDate, axiosPrivate) => {
  try {
    const response = await axiosPrivate.get(
      `/teams/myospermuscle/${selPlayer}/${startDate}/${endDate}/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching player data:", error);
    throw error;
  }
};

export { fetchMuscles, fetchMyoPerMuscle };
