const fetchDDModelData = async (selPlayer, selMuscle, loc, refDate, axiosPrivate) => {
  try {
    const response = await axiosPrivate.get(
      `/models/dd/${selPlayer}/${selMuscle}/${loc}/${refDate}/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );

    return response.data;

  } catch (error) {
    console.error("Error fetching DD model data:", error);
    throw error;
  }
};

const fetchSSModelData = async (selPlayer, selMuscle, loc, axiosPrivate) => {
  try {
    const response = await axiosPrivate.get(
      `/models/ss/${selPlayer}/${selMuscle}/${loc}/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );

    return response.data;

  } catch (error) {
    console.error("Error fetching SS model data:", error);
    throw error;
  }
};

const fetchSPSSModelData = async (selPlayer, selMuscle, loc, axiosPrivate) => {
  try {
    const response = await axiosPrivate.get(
      `/models/spss/${selPlayer}/${selMuscle}/${loc}/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );

    return response.data;

  } catch (error) {
    console.error("Error fetching SPSS model data:", error);
    throw error;
  }
};

export { fetchDDModelData, fetchSSModelData, fetchSPSSModelData };
