const fetchPlayerACWR = async (pid, group, startDate, endDate, axiosPrivate) => {
  try {
        const response = await axiosPrivate.get(`/teams/playeracwr/${pid}/${group}/${startDate}/${endDate}/`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            }
        );

        return response.data;

    } catch (error) {
        console.error('Error fetching player ACWR:', error);
        throw error;
    }
};

export { fetchPlayerACWR  };