const fetchRoster = async (axiosPrivate) => {
  try {
    const response = await axiosPrivate.get("/teams/roster/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetching players failed:", error);
    throw error;
  }
};

const fetchRosterStatus = async (axiosPrivate) => {
  try {
    const response = await axiosPrivate.get(`/teams/rosterstatus/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    let data = response.data;
    return data;

  } catch (error) {
    console.error("Error fetching roster players:", error);
    throw error;
  }
};

const fetchRosterGood = async (axiosPrivate) => {
  try {
    const response = await axiosPrivate.get(`/teams/roster_good/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    let data = response.data;
    return data;

  } catch (error) {
    console.error("Error fetching roster players:", error);
    throw error;
  }
};

export { fetchRosterStatus, fetchRoster, fetchRosterGood };
