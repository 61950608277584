const fetchKinexonSessions = async (axiosPrivate) => {
  try {
        const response = await axiosPrivate.get("/input/kinexonsessions/", {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching Kinexon sessions:", error);
        throw error;
    }
};

export { fetchKinexonSessions };