import { useState, useEffect } from "react";

import { fetchAvatar } from "src/services/auth";
import { fetchTeamIcon } from "src/services/apis";

import noneavatar from "src/assets/images/avatars/none.png";
import { useGlobalState } from "src/contexts";
import { useAxiosPrivate } from "./useAxiosPrivate";

const useUserIcon = () => {
    const [userIcon, setUserIcon] = useState(noneavatar);
    const { state } = useGlobalState();
    const { axiosPrivate } = useAxiosPrivate();

    useEffect(() => {
        const getAvatar = async () => {
            try {
                if (!state.userInfo || !state.userInfo.avatar) {
                    const data = await fetchAvatar(axiosPrivate);
                    setUserIcon("/static/" + data.avatar);
                } else {
                    setUserIcon("/static/" + state.userInfo.avatar);
                }
            } catch (error) {
                console.error('An error occurred when loading Avatar:', error);
            }
        };

        getAvatar();
    }, []);

    return { userIcon };
};


const useTeamIcon = () => {
    const [teamIcon, setTeamIcon] = useState(noneavatar);
    const { axiosPrivate } = useAxiosPrivate();

    useEffect(() => {
        const loadTeamIcon = async () => {
            try {
                const data = await fetchTeamIcon(axiosPrivate);
                setTeamIcon("/static/" + data[0].image);
            } catch (error) {
                console.error('An error occurred when loading Avatar:', error);
            }
        };

        loadTeamIcon();
    }, [])

    return { teamIcon };
};

export { useUserIcon, useTeamIcon };
